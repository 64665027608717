import t from 'typy'

export const isRichTextEmpty = content => {
  let isEmpty = true
  if (content && t(content, 'content').safeObject) {
    Object.keys(content.content).forEach(key => {
      if (t(content.content[key], 'content').safeObject) {
        Object.keys(content.content[key].content).forEach(subKey => {
          if (content.content[key].content[subKey].value) {
            isEmpty = false
          }
        })
      }
    })
  }
  return isEmpty
}
