import * as React from 'react'
import { inject, observer } from 'mobx-react'

import Highlights from 'src/components/Modules/Highlights'
import ProductGrid from 'src/components/ProductGrid/ProductGrid'
import StartpageDescription from 'src/components/Modules/StartpageDescription'
import startpages from 'src/json/startpages.json'
import { getCurrentSiteData } from 'src/helpers/getCurrentSiteData'
import { getFeaturedProducts } from 'src/utils/product'
import { isRichTextEmpty } from 'src/helpers/isRichTextEmpty'

type Props = {
  props: any
}

type TemplateProps = {
  pageContext: any
  preview?: any
}

@inject('preview')
@observer
class IndexTemplate extends React.Component<TemplateProps> {
  render() {
    let { pageContext, preview } = this.props

    if (preview && preview.previewData) {
      const previewStartpage = preview.getCurrentPageContext(
        pageContext.contentType,
        pageContext.locale
      )
      if (previewStartpage) {
        pageContext = previewStartpage
      }
    }

    const { heroHighlight, highlights, seoText } = pageContext
    const products = getFeaturedProducts()

    return (
      <React.Fragment>
        {heroHighlight && (
          <Highlights fullWidth={true} highlights={[heroHighlight]} />
        )}
        {products && products.length > 0 && (
          <ProductGrid fluidStyle={true} products={products} />
        )}
        {highlights && highlights.length > 0 && (
          <Highlights highlights={highlights} />
        )}
        {!isRichTextEmpty(seoText) && (
          <StartpageDescription content={seoText} />
        )}
      </React.Fragment>
    )
  }
}

const Index = (props: Props) => (
  <IndexTemplate {...props} pageContext={getCurrentSiteData(startpages)} />
)

export default Index
