import * as React from 'react'

import Highlight from 'src/components/Highlight/Highlight'

import styles from './Highlights.module.scss'

type Props = {
  highlights: any
  fullWidth?: boolean
}

const Highlights = ({ highlights, fullWidth }: Props) => {
  if (highlights.length < 1) {
    return null
  }
  return fullWidth ? (
    <div className={styles.highlightsFullWrapper}>
      {highlights.map((highlight: any, index: number) => (
        <Highlight fullWidth={fullWidth} highlight={highlight} key={index} />
      ))}
    </div>
  ) : (
    <div className={styles.highlightsWrapper}>
      {highlights.map((highlight: any, index: number) => (
        <Highlight fullWidth={fullWidth} highlight={highlight} key={index} />
      ))}
    </div>
  )
}

export default Highlights
