import * as React from 'react'
import t from 'typy'

import BorderButton from 'src/components/UI/Button/BorderButton'
import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import { ArrowRightIcon } from 'src/components/UI/Icon/Icon'

import styles from './Highlight.module.scss'

type Props = {
  highlight: {
    fields: {
      title: string
      slug: string
      image: {
        fields: any
      }
      buttonText: string
    }
  }
  fullWidth?: boolean
}

const Highlight = ({ highlight, fullWidth }: Props) => {
  const { title, slug, image, buttonText } = highlight.fields
  const locale = t(highlight, 'sys.locale').safeObject
  const wrapperClass = fullWidth
    ? [styles.highlight, styles.fullWidth].join(' ')
    : [styles.highlight, styles.halfWidth].join(' ')
  return (
    <LinkComponent to={slug} locale={locale} className={wrapperClass}>
      <div className={fullWidth ? {} : styles.smallHover}>
        <div className={styles.backgroundImage}>
          <Image
            src={image.fields.file.url}
            alt={image.fields.title}
            isCritical={fullWidth}
            size={fullWidth ? '100vw' : '50vw'}
          />
        </div>
        <div className={fullWidth ? styles.fullContent : styles.content}>
          {fullWidth ? (
            <div className={styles.bigText}>
              <span>{title}</span>
              {buttonText && <BorderButton>{buttonText}</BorderButton>}
            </div>
          ) : (
            <div className={styles.text}>
              <span>{title}</span>
              <ArrowRightIcon width={24} height={24} color="white" />
            </div>
          )}
        </div>
      </div>
    </LinkComponent>
  )
}
export default Highlight
