import * as React from 'react'
import t from 'typy'

import RichText from './RichText'
import styles from './StartpageDescription.module.scss'

type Props = {
  content: {
    content: any
  }
}

const StartpageDescription = ({ content }: Props) => (
  <div className={styles.wrapper}>
    <div className="container">
      <div className={styles.startpageDescription}>
        <RichText content={content} />
      </div>
    </div>
  </div>
)

export default StartpageDescription
