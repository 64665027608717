import * as React from 'react'

import styles from './BorderButton.module.scss'

type Props = {
  children: any
  onClick?: any
  disabled?: boolean
}

const BorderButton = ({ children, onClick, disabled }: Props): JSX.Element => (
  <button
    className={disabled ? styles.disabledPrimaryButton : styles.primaryButton}
    onClick={onClick}
  >
    {children}
  </button>
)

export default BorderButton
